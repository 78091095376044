// MyDocuments.js
import './style.css'
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../context/AuthContext'; // Path to your AuthContext
import {getAllDocuments} from '../../utils/functions/getAllDocuments';
import {useDocument} from "../../context/DocumentContext";
import DocumentViewer from "../documentviewer/DocumentViewer"; // Path to your Firebase service

function DocumentsList() {
    const {currentUser} = useAuth();
    const [documents, setDocuments] = useState([]);
    const {currentDocument, setCurrentDocument} = useDocument();

    const handleDocumentSelect = (document) => {
        setCurrentDocument(document);// Update the current document in context
    };


    useEffect(() => {
        if (currentUser) {
            getAllDocuments(currentUser)
                .then(docs => setDocuments(docs))
                .catch(error => console.error('Error fetching documents:', error));
        }
    }, [currentUser]);

    if (!currentUser) {
        return <div>Please sign in to view your documents.</div>;
    }

    return (
        <div className="p-8 relative z-10 L-document-list">
            <h1 className="text-2xl font-semibold mb-4">My Documents</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {documents.map(doc => (
                    <div
                        key={doc.fileId}
                        className="border p-4 rounded hover:shadow-lg transition-shadow cursor-pointer"
                        onClick={() => handleDocumentSelect(doc)}
                    >
                        <h2 className="L-documentlist-h2 text-lg font-medium">{doc.fileName}</h2>
                        <p className="text-sm text-gray-600">Uploaded: {doc.uploadedAt.toDate().toDateString()}</p>
                    </div>
                ))}
            </div>

            {currentDocument && (
                <DocumentViewer fileUrl={currentDocument.fileUrl} fileName={currentDocument.fileName}
                                requestHeaders={{"Access-Control-Allow-Origin": "http://localhost:3000"}}/>
            )}
        </div>
    );
};

export default DocumentsList;
