import Divider from "@mui/material/Divider";

function TermsAndConditions() {
  return (
    <div className="px-6 py-12">
      <h1 className="text-[24px] font-semibold">Terms and Conditions</h1>
      <Divider />
      <p className="py-8">
        This document outlines the Terms and Conditions (hereinafter referred to
        as "Terms") for the use of services and software (hereinafter referred
        to as "Software") provided by Legal Tech Solutions LLC (hereinafter
        referred to as "Company"), a legal entity incorporated under the laws of
        the Republic of Armenia. By accessing or using the Software, users and
        customers (hereinafter referred to as "Users") agree to be bound by
        these Terms. If you do not agree with any part of these Terms, you must
        not use the Software.
      </p>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          1. Definition of Software
        </h2>
        <p className="pt-2">
          For the purposes of these Terms, "Software" refers to the Company's
          contract automation software, including a risk assessment tool that
          identifies risks in contracts and provides mitigation measures. The
          Software analyzes contracts, offers related information, and generates
          a track-changes version of the contract based on the mitigation
          measures provided by the Software. It is designed to assist Users in
          identifying potential legal and contractual risks, but not to replace
          the advice of a qualified legal professional.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          2. Use of the Software
        </h2>
        <p className="pt-2">
          Users are granted a non-exclusive, non-transferable, non-assignable,
          non-sublicensable revocable license to access and use the Software
          strictly in accordance with these Terms. The Company reserves all
          rights not expressly granted to Users in these Terms.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          3. Limitation of Liability
        </h2>
        <p className="pt-2">
          The Company provides the Software on an "as is" basis and expressly
          disclaims all warranties, express or implied, including but not
          limited to warranties of merchantability, fitness for a particular
          purpose, and non-infringement. Users acknowledge that the provision
          and use of the Software are experimental and agree that the Company
          shall not be liable for any damages, whether direct, indirect,
          incidental, special, consequential, or exemplary, arising out of or in
          connection with the use of the Software or as a result of the
          recommendations or mitigation measures provided by the Software. This
          includes, but is not limited to, liability arising from:{" "}
        </p>
        <div>
          <ul>
            <li>- Loss of data or data being rendered inaccurate,</li>
            <li>- Failure of the Software to function as intended,</li>
            <li>
              - Unauthorized access to or alteration of Users' transmissions or
              data,
            </li>
            <li>- Statements or conduct of any third party on the Software,</li>
            <li>- Any other matter relating to the Software.</li>
          </ul>
        </div>
        <p>
          The above list is not exhaustive. The Company's total liability to any
          User for any claim arising out of or in connection with the use of the
          Software shall be limited to the amount the User has paid to the
          Company for the use of the Software, if any.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          4. Indemnification
        </h2>
        <p className="pt-2">
          Users agree to indemnify, defend, and hold harmless the Company, its
          officers, directors, employees, agents, licensors, and suppliers from
          and against all losses, expenses, damages, and costs, including
          reasonable attorneys' fees, resulting from any violation of these
          Terms or any activity related to the use of the Software (including
          negligent or wrongful conduct) by Users or any other person accessing
          the Software using Users' Internet account.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          5. Governing Law and Jurisdiction
        </h2>
        <p className="pt-2">
          These Terms are governed by and construed in accordance with the laws
          of the Republic of Armenia, without regard to its conflict of law
          principles. Users agree to submit to the personal and exclusive
          jurisdiction of the courts located within Armenia for any disputes
          arising out of or related to these Terms or the Software.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
          6. Miscellaneous
        </h2>
        <p className="pt-2">
          If any provision of these Terms is found to be unlawful, void, or for
          any reason unenforceable, then that provision shall be deemed
          severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions. These Terms constitute the
          entire agreement between Users and the Company relating to the subject
          matter herein and shall not be modified except in writing, signed by
          both parties, or by a change to these Terms or Guidelines published by
          the Company.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
