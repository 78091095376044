import { useState } from "react";
import FeedbacksEditor from ".";

const OpenFeedbackModal = ({
    documentId
}) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState("");

    return (
        <div>
            <button className="px-2.5 py-1.5 rounded-md text-white bg-indigo-600 hover:bg-indigo-800"
                onClick={() => setOpen(true)}
            >
                { content.length > 0 ? "Update Feedbacks" : "Feedbacks" }
            </button>
            <FeedbacksEditor 
                open={open}
                setOpen={setOpen}
                documentId={documentId}
                saveContent={setContent}
            />

            {content.length > 0 && 
                <div dangerouslySetInnerHTML={{ __html: content }} />
            }
        </div>
    )
}

export default OpenFeedbackModal;