import { useState } from "react";
import Thumbnail from "../thumbs";

const MatrixTextualItem = ({ id, currentId, text, mode, category, risky_sentence, documentId, currentCategory }) => {
  const [localText, setLocalText] = useState(text);

  return (
    <div className="flex flex-col items-end relative">
      {localText}
      {category === currentCategory && (
        <Thumbnail
          id={id}
          mode={mode}
          category={category}
          setLocalText={setLocalText}
          text={text}
          risky_sentence={risky_sentence}
          documentId={documentId}
        />
      )}
    </div>
  );
};

export default MatrixTextualItem;