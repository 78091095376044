import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const InformationAlert = ({ text }) => {

    const [status, setStatus] = useState(true);

    if (status) {
        return (
            <div className="rounded-md bg-blue-100 p-4">
                <div className="flex lg:justify-between items-start lg:items-center">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    <div className="ml-3 flex-1 md:flex md:justify-between items-center">
                        <p className="text-sm text-blue-700">
                            {text}
                        </p>
                    </div>
                    <div className="text-sm md:ml-6 md:mt-0">
                        <button
                            type="button"
                            className="inline-flex rounded-md lg:p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                            onClick={() => setStatus(false)}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default InformationAlert;