import "./style.css";
import { PrimaryButton, SecondaryButton } from "../../components/button/Button";
import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import logoo from '../../assets/img/Untitled-3.png'
import { useAuth } from "../../context/AuthContext";
import ProfileDropdown from "../../components/profiledropdown/ProfileDropdown";

function Header() {

    const { isAuthenticated } = useAuth()

    return (
        <div className="L-header-main">
            <div className="L-head">
                <div className="L-header-name">
                    <div className="L-head-logo" style={{ backgroundImage: `url(${logoo})` }} />
                    <NavLink to='/'><span className="L-header-name-t">Contractee</span></NavLink>
                </div>
                <div className="L-header-right">
                    <div className="topnavm">
                        <div className="dropdownn">
                            <button className="dropbtn !text-white"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="bg-white" width="30" height="30" viewBox="0 0 50 50">
                                <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
                            </svg>
                                <i className="fa fa-caret-down"></i>
                            </button>
                            <div className="dropdownn-content">
                                <NavLink className='navlink' to='/'>
                                    Home
                                </NavLink>
                                <NavLink className='navlink' to='risk'>
                                    Risk Assessment
                                </NavLink>
                                <NavLink className='navlink' to='assign'>
                                    Task Assignment
                                </NavLink>
{/*                                 <NavLink className='navlink' to='contract-copilot'>
                                        Contract Copilot
                                    </NavLink> */}
                                <NavLink className='navlink disabled' to=''>
                                    Drafting Tool
                                </NavLink>
                                <NavLink to='/contact' className='navlink'>
                                    Contact
                                </NavLink>
                                <NavLink to='about' className='navlink'>
                                    About us
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="topnav">
                        <div className="topnavnone">
                            <NavLink className='navlink' to='/'>
                                Home
                            </NavLink>
                            <div className="dropdown">
                                <button className="dropbtn">Solutions
                                    <i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <NavLink className='navlink' to='risk'>
                                        Risk Assessment
                                    </NavLink>
                                    <NavLink className='navlink' to='grammar'>
                                        Grammar Checker
                                    </NavLink>
                                    <NavLink className='navlink' to='assign'>
                                        Task Assignment
                                    </NavLink>
{/*                                     <NavLink className='navlink' to='contract-copilot'>
                                        Contract Copilot
                                    </NavLink> */}
                                    <NavLink className='navlink disabled' to=''>
                                        Drafting Tool
                                    </NavLink>
                                </div>
                            </div>
{/*                             <NavLink to='pricing' className='navlink'>
                                Pricing
                            </NavLink> */}
                            <NavLink to='about' className='navlink'>
                                About us
                            </NavLink>
                            <NavLink to='/contact' className='navlink'>
                                Contact
                            </NavLink>
                        </div>
                        {isAuthenticated ? (
                            <ProfileDropdown />
                        ) : (
                            <>
                                <div className="topnavm">
                                    <div className="dropdownn">
                                        <button className="dropbtn">Join us
                                            <i className="fa fa-caret-down"></i>
                                        </button>
                                        <div className="dropdownn-content">
                                            <PrimaryButton route='/signup' text="Sign Up" />
                                            <SecondaryButton route='/signin' text="Log In" />
                                        </div>
                                    </div>
                                </div>
                                <div className="L-sign-in-none">
                                    <PrimaryButton route='/signup' text="Sign Up" />
                                    <SecondaryButton route='/signin' text="Log In" />
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
