import React, { createContext, useContext, useState } from 'react';

const DocumentContext = createContext(null);

export const useDocument = () => useContext(DocumentContext);

export const DocumentProvider = ({ children }) => {
    const [currentDocument, setCurrentDocument] = useState(null);

    const value = {
        currentDocument,
        setCurrentDocument,
    };

    return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};