import React, { useEffect } from 'react'
import { useState } from "react";
import PricingCard from "../../components/pricingcard";
import './style.css';

function Pricing() {
  const [selectMonthly, setSelectMonthly] = useState(true);


  const handlePaymentSuccess = (plan) => {
    switch (plan.title) {
      case 'Essential (free trial)':
        console.log("free trial");
        break;
      case 'Deluxe':
        console.log("deluxe");
        break;
      case 'Premium':
        console.log("premium");
        break;
      default:
        break;
    }
  }

  return (
    <div className="PricingApp">
      <div className="app-container">
        {/* Header */}
        <header>
          <h1 className="header-topic">Our Pricing Plan</h1>
          <div className="header-row">
            <p>Annually</p>
            <label className="price-switch">
              <input
                className="price-checkbox"
                onChange={() => {
                  setSelectMonthly((prev) => !prev);
                }}
                type="checkbox"
              />
              <div className="switch-slider"></div>
            </label>
            <p>Monthly</p>
          </div>
        </header>
        {/* Cards here */}
        <div className="pricing-cards">
          <PricingCard
            plan={{
              id: "free",
              title: "Essential (free trial)",
              price: selectMonthly ? "0$" : "0$",
              storage: "60 GB Storage",
              users: "5",
              sendUp: "5"
            }}
            onSuccess={handlePaymentSuccess}
          />
          <PricingCard
            plan={{
              id: "deluxe",
              title: "Deluxe",
              price: selectMonthly ? "99$" : "999$",
              storage: "70 GB Storage",
              users: "10",
              sendUp: "10"
            }}
            onSuccess={handlePaymentSuccess}
          />
          <PricingCard
            plan={{
              id: "premium",
              title: "Premium",
              price: selectMonthly ? "45$" : "399$",
              storage: "90 GB Storage",
              users: "20",
              sendUp: "7"
            }}
            onSuccess={handlePaymentSuccess}
          />
        </div>
      </div>
      
    </div>
  );
}

export default Pricing;