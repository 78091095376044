import { database } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const documentRef = collection(database, "documents");
export const getAllDocuments = async (user) => {
    try {
        const q = query(documentRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error getting documents: ", error);
        throw error;
    }
};
