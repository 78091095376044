import React, { useEffect } from 'react'
import './style.css'
import { preLoaderAnim } from '../animations'

export const Preloader = () => {
    useEffect(() =>{
        preLoaderAnim();
    },[])
  return (
    <div className="preloader">
        <div className="texts-container">
            <span>Automate,</span>
            <span>Save,</span>
            <span>Succeed,</span>
        </div>
    </div>
  )
}
