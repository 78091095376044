import React from 'react';
import MatrixTextualItem from '../matrix-textual-item';
import FeedbacksEditor from '../feedbacks-editor';
import OpenFeedbackModal from '../feedbacks-editor/open';

const RiskMatrix = ({
    tableData,
    currentCategory
}) => {
    return (
        <table className="w-full border-collapse border text-center">
            {/* Table header */}
            <thead>
                <tr className="bg-red-700 font-semibold">
                    <th className="border p-2">Risk of category</th>
                    <th className="border p-2">Clause</th>
                    <th className="border p-2">Brief risk definition</th>
                    <th className="border p-2">Probability</th>
                    <th className="border p-2">Impact on Client</th>
                    <th className="border p-2">Mitigation Measures</th>
                    <th className="border p-2">Probability after mitigation</th>
                    <th className="border p-2">Impact after Mitigation</th>
                    <th className="border p-2">Average risk score before and after Mitigation</th>
                    <th className='border p-2'>Feedbacks</th>
                </tr>
            </thead>

            {/* Table body */}
            <tbody>
                {
                    tableData.length > 0 &&
                    tableData.map((data, index) => (
                        <tr key={index} className={`${currentCategory === data.category ? 'bg-blue-300 animate-pulse' : ''}`}>
                            <td className="border p-2 bg-indigo-300">{data.category}</td>
                            <td className="border p-2">
                                <MatrixTextualItem
                                    currentCategory = {currentCategory}
                                    documentId = {data.documentId}
                                    text={data.clause}
                                    mode="clause"
                                    category={data.category}
                                    risky_sentence={data.clause}
                                />
                            </td>
                            <td className="border p-2">
                                <MatrixTextualItem
                                    currentCategory = {currentCategory}
                                    documentId = {data.documentId}
                                    text={data.riskDefinition}
                                    mode="risk_definition"
                                    category={data.category}
                                    risky_sentence={data.clause}
                                />
                            </td>
                            <td className="border p-2">{data.probability}</td>
                            <td className="border p-2">{data.impact}</td>
                            <td className="border p-2">
                                <MatrixTextualItem
                                    currentCategory = {currentCategory}
                                    documentId = {data.documentId}
                                    text={data.mitigation}
                                    mode="mitigation_measure"
                                    category={data.category}
                                    risky_sentence={data.clause}
                                />
                            </td>
                            <td className="border p-2">{data.probabilityAfterMitigation}</td>
                            <td className="border p-2">{data.impactAfterMitigation}</td>
                            <td className="border p-2">{data.averageRiskScore.toString()}</td>
                            <td className="border p-2">
                                { currentCategory === data.category && <OpenFeedbackModal documentId={data.documentId}/>}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
};

export default RiskMatrix;