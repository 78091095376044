import { Fragment, useRef, useState } from 'react';

import AddAccountDialog from '../../components/add-account-dialog';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, database } from '../../firebase';
import { addDoc, collection } from 'firebase/firestore';
import {v4 as uuidv4} from "uuid";
import { useNavigate } from "react-router-dom";

const LawyerRegistration = () => {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([
        { name: 'Lindsay Walton', email: 'lindsay.walton+1@example.com', company: 'Sproutzo', password: 'password' },
    ]);

    const navigate = useNavigate();

    /**
     * 
     * @param {*} newUser 
     */
    const addUser = (newUser) => {
        setUsers((prevUsers) => [...prevUsers, newUser]);
    }

    /**
     * 
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("current user: ", auth.currentUser)
        
        users.forEach(async (user) => {
            await createUserWithEmailAndPassword(auth, user.email, user.password)
                    .then(async (userCredential) => {
                        const user = userCredential.user;

                        try {
                            const userRef = await addDoc(collection(database, "users"), {
                                uid: uuidv4(),
                                name: user.name,
                                email: user.email,
                                company: user.company,
                                password: user.password,
                                subScriptionStatus: 'active',
                                createdAt: new Date()
                            });

                            await sendEmailVerification(auth.currentUser)
                            navigate("/confirm-email");
                        } catch (error) {
                            console.log("Error creating user or sending email verification: ", error)
                        }
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                    })
        })
    }

    return (
        <div className="bg-gray-900">

            <AddAccountDialog
                open={open}
                setOpen={setOpen}
                addUser={addUser}
            />

            <div className="mx-auto max-w-7xl">
                <div className="bg-gray-900 py-10 min-h-screen">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-white">Users</h1>
                                <p className="mt-2 text-sm text-gray-300">
                                    A list of all the users in your account including their name, title, email and role.
                                </p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <button
                                    type="button"
                                    className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    onClick={() => { setOpen(true) }}
                                >
                                    Add user
                                </button>
                            </div>
                        </div>
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Company
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Password
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-800">
                                            {users.map((user) => (
                                                <tr key={user.email}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                                        {user.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{user.email}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{user.company}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{user.password}</td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                        <a href="#" className="text-indigo-400 hover:text-indigo-300">
                                                            Edit<span className="sr-only">, {user.name}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <div className="py-8">
                            <button 
                                className="bg-indigo-600 rounded-lg text-white px-1.5 py-2.5"
                                onClick={handleSubmit}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LawyerRegistration;