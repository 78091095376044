import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { PaymentContext } from "../../context/PaymentContext";
import PaymentDialog from "../../components/payment-dialog";

const PaymentStatus = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentIntent = searchParams.get("payment_intent");
    // const backendUrl = process.env.; //
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const [paymentStatus, setPaymentStatus] = useState(null);

    const { savePaymentData } = useContext(PaymentContext);

    useEffect(() => {
        const urlString = window.location.href;
        const startIndex = urlString.indexOf('payment_intent=') + 'payment_intent='.length;
        const endIndex = urlString.indexOf('&payment_intent_client_secret');

        const paymentIntent = urlString.substring(startIndex, endIndex);

        axios
            .post(`${backendUrl}/payment/status`, { paymentIntent })
            .then(res => {
                setPaymentStatus(res.data.msg);
                savePaymentData(res.data.msg);
            })
            .catch(error => {
                console.log('Error retrieving payment status: ', error);
            })
    }, [])

    return (
        <div>
            {
                paymentStatus ? (
                    <div className="relative">
                        {
                            paymentStatus === "active" &&
                            <PaymentDialog />
                        }
                    </div>
                ) : (
                    <p>Loading payment status...</p>
                )
            }
        </div>
    )
}

export default PaymentStatus;
