import React, { useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import DocxModal from '../dialog/docx-dialog';
import MatrixModal from '../dialog/matrix-dialog';
import ChangeTrackerDialog from '../dialog/tracker-dialog';


const Table = ({
    content,
    htmlContent,
    saveContentChanges
}) => {
    const [docxOpen, setDocxOpen] = useState(false);
    const [matrixOpen, setMatrixOpen] = useState(false);
    const [trackerOpen, setTrackerOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState("");

    /**
     * @abstract This function is used to set currently selected category, so it will help doing auto indication...
     * @param {*} category 
     */
    const handleMatrixOpen = (category) => {
        setCurrentCategory(category);
        setMatrixOpen(true);
    }

    const handleTrackerOpen = (category) => {
        setCurrentCategory(category);
        setTrackerOpen(true);
    }

    /**
     * This function is used to save the changes by the track changes functionality...
     */
    const handleContentChanges = (values) => {
        saveContentChanges({ sentence:values, content, currentCategory });
    }

    return (
        <div className="px-4 w-full sm:px-6 lg:px-8 bg-blue-50">
            <DocxModal 
                docxOpen={docxOpen}
                setDocxOpen={setDocxOpen}
                htmlContent={htmlContent}
            />

            <MatrixModal 
                matrixOpen={matrixOpen}
                setMatrixOpen={setMatrixOpen}
                content={content}
                currentCategory={currentCategory}
            />

            <ChangeTrackerDialog 
                trackerOpen={trackerOpen}
                setTrackerOpen={setTrackerOpen}
                content={content}
                currentCategory={currentCategory}
                handleContentChanges={handleContentChanges}
            />


            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        <a href="#" className="group inline-flex">
                                            Criteria
                                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </a>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <a href="#" className="group inline-flex">
                                            Raw Sentence
                                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </a>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <a href="#" className="group inline-flex">
                                            Options
                                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                <ChevronDownIcon
                                                    className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </a>
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {content.map((item) => (
                                    <tr key={item.category}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {item.category}
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-900">{item.clause}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                                            {/* <button
                                                className='bg-indigo-600 px-1.5 py-2.5 rounded-md text-white mr-2 hover:bg-indigo-800 hover:outline-double hover:outline-2'
                                                onClick={() => handleTrackerOpen(item.category)}
                                                
                                            >
                                                Contract Review
                                            </button> */}
                                            <button 
                                                className="bg-blue-600 px-1.5 py-2.5 rounded-md text-white mr-2 hover:bg-blue-400 hover:outline-double hover:outline-2"
                                                onClick={() => setDocxOpen(true)}
                                            >
                                                    See in Docx
                                            </button>
                                            <button 
                                                className="bg-blue-600 px-1.5 py-2.5 rounded-md text-white hover:bg-blue-400 hover:outline-double hover:outline-2"
                                                onClick={() => handleMatrixOpen(item.category)}
                                            >
                                                See in Matrix
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table;