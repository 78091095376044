import { api } from "./base";

export const postDocument = async (params) => {
  const response = await api.post("/upload", params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const saveToDB = async (params) => {
  const response = await api.post("/save-to-db", params);
  return response;
}

export const setVote = async (params) => {
  const response = await api.post("/document/set-vote", params);
  return response;
};

export const getVote = async (params) => {
  const response = await api.post("/document/get-vote", params);
  return response;
};

export const regenerateText = async (params) => {
  const response = await api.post("/document/document-regenerate-text", params);
  return response;
};

export const trackChanges = async (params) => {
  const response = await api.post("/document/track-changes", params);
  return response;
};

export const getAllRiskyClauses = async (params) => {
  const response = await api.post("/document/get-all-risky-clauses", params);
  return response;
};

export const copilotTrackChange = async (params) => {
  const response = await api.post("/document/copilot-track-changes", params);
  return response;
};

export const extractDataPattern = async (params) => {
  const response = await api.post("/document/extract-data-pattern", params);
  return response;
};

export const saveFeedbacks = async (params) => {
  const response = await api.post("/document/save-feedbacks", params);
  return response;
}

export const convertDocumentToSFDT = async (params) => {
  const response = await api.post("/document/convert-to-sfdt", params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export const assessmentForUpdatedDocx = async (params) => {
  const response = await api.post("/document/assessment-for-updated-docx", params);
  return response;
}