import React from "react";
import "./style.css";
import { NavLink } from "react-router-dom";

const PricingCard = ({ plan, onSuccess }) => {
  return (
    <div
      className="PricingCard"
    >
      <header>
        <p className="card-title">{plan.title}</p>
        <h1 className="card-price">{plan.price}</h1>
      </header>
      {/* features here */}
      <div className="card-features">
        <div className="card-storage">{plan.storage}</div>
        <div className="card-users-allowed">{plan.users} users in total</div>
        {/* <CardElement /> */}

        <div className="card-send-up">Send up to {plan.sendUp}</div>
      </div>
      <NavLink
        to={`/checkout/${plan.id}`}
      >
        <div className="card-btn">BUY NOW</div>
      </NavLink>
      {/* <NavLink
        to={`/lawyer-registration/`}
      >
        <div className="card-btn">BUY NOW</div>
      </NavLink> */}
    </div>
  );
};

export default PricingCard;