import Divider from "@mui/material/Divider";

function PrivacyPolicy() {
  return (
    <div className="px-6 py-12">
      <h1 className="text-[24px] font-semibold">Privacy Policy</h1>
      <Divider />
      <p className="py-8">
      Welcome to www.contractee.net. Your privacy and the security of your personal data are of paramount importance to us. This Privacy Policy outlines the types of information we collect from you, how we use it, who we share it with, and the rights you have regarding your personal data. By using our website, you agree to the collection and use of information in accordance with this policy.

      </p>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        General Information
        </h2>
        <p className="pt-2">
        www.contractee.net is operated by Legal Tech Solutions LLC., which is committed to protecting your privacy. This Privacy Policy applies to our website and covers our processing activities as data controllers.

        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Data We Collect

        </h2>
        <p className="pt-2">
        We collect both personally identifiable information and non-personal data to provide and improve our services. Personally identifiable information may include, but is not limited to, your name, email address, telephone number, and reasonably - any other information that can be directly linked to you. Non-personal data includes aggregated usage data and technical information transmitted by your device.

        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        The Lawful Basis for Processing

        </h2>
        <p className="pt-2">
        Our processing of your personal data is based on the necessity to perform a contract with you, comply with our legal obligations, protect your vital interests, or pursue our legitimate business interests. We will always seek your consent for processing where required by law.

        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        How We Use Your Data

        </h2>
        <p className="pt-2">
        Your data is used to provide and enhance our services, process transactions, comply with legal requirements, improve website functionality, and for marketing purposes with your consent.
        </p>
      </div>

      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Who We Share Your Data With

        </h2>
        <p className="pt-2">
        We may share your data with trusted third parties who provide essential services, with government authorities where required by law, and with potential business partners. Any data sharing will respect your privacy rights and be covered by appropriate safeguards.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Data Security


        </h2>
        <p className="pt-2">
        We employ industry-standard security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These include access controls and secure data storage.

        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Your Rights

        </h2>
        <p className="pt-2">
        You have the right to access, correct, or delete your personal data, restrict processing, and, where applicable, data portability. You can also withdraw consent at any time, subject to legal or contractual restrictions.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        International Data Transfers

        </h2>
        <p className="pt-2">
        We may transfer your personal data to countries outside of your jurisdiction. In such cases, we ensure that adequate safeguards are in place to protect your privacy rights in line with this policy.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Children's Privacy

        </h2>
        <p className="pt-2">
        Our website is not intended for children, and we do not knowingly collect personal data from children. If we become aware of such collection, we will take steps to delete the information as soon as possible.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Cookie Policy

        </h2>
        <p className="pt-2">
        Our website uses cookies and similar technologies to improve user experience and analyze website performance. You can control the use of cookies through your browser settings.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Data Retention

        </h2>
        <p className="pt-2">
        We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for compliance with legal obligations.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Changes to This Policy

        </h2>
        <p className="pt-2">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </div>
      <div className="py-6">
        <h2 className="font-semibold text-[16px] text-blue-500">
        Contact Us

        </h2>
        <p className="pt-2">
        If you have any questions about this Privacy Policy, please contact us via email at info@contractee.net        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
