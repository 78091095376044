import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DocumentIcon } from "@heroicons/react/24/outline";
import Flmngr from "@flmngr/flmngr-react";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import { addDocumentToFirestore } from "../../utils/functions/addDocumentToFirestore";

import { useAuth } from "../../context/AuthContext";
import InformationAlert from "../information";
import Spinner from "../spinner";
import Table from "../table";
import {
  convertDocumentToSFDT,
  postDocument,
  saveToDB,
} from "../../api/document";

import "./style.css";
import GrammarCheckerDialog from "../grammar-checker-dialog";

function DocumentUpload1() {
  const text =
    "Our risk assessment feature delivers insights into your contract risks. Our software engages in real-time discussions, providing a summary of the most critical risk factors associated with the contract.";

  // status...
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [status, setStatus] = useState("not_started"); // not_started, sent, received
  const [showGrammarModal, setShowGrammarModal] = useState(false);
  const [isProceed, setIsProceed] = useState(null);
  const [sfdt, setSFDT] = useState({});

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // reading files...
  const [content, setContent] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");

  const { currentUser } = useAuth();
  const fileInputRef = useRef(null);

  /**
   * @desc This function is used to handle changes of content...
   * @param {*} values
   */
  const handleContentChanges = (values) => {
    const { sentence, content, currentCategory } = values;

    const updatedContent = htmlContent.replace(
      content.filter((item) => {
        return item.category === currentCategory;
      })[0].clause,
      sentence
    );

    setHtmlContent(updatedContent);
  };

  /**
   * @abstract handling the changed file...
   * @param {*} event
   */
  const handleFileChange = (event) => {
    if (event?.target?.files?.length > 0) {
      // Saving the formData to LocalStorage so that it can be used to convert to SFDT...
      if (localStorage.getItem("word-docx")) {
        localStorage.removeItem("word-docx");
      }
      localStorage.setItem("word-docx", JSON.stringify(event.target.files[0]));

      console.log("event.target.files[0]: ", event.target.files[0]);

      setSelectedFile(event.target.files[0]);
      setUploadStatus("");
    }
  };

  /**
   * @abstract handling the uploaded file...
   * @returns
   */
  const handleFileUpload = () => {
    // setShowGrammarModal(true);
    if (!selectedFile) {
      setUploadStatus("Please select a file to upload.");
      return;
    }

    let selectedFileBlob = null;

    selectedFileBlob = new Blob([selectedFile], {
      type: ".doc,.docx,application/pdf,.pdf",
    });

    const storage = getStorage();
    const uploadRef = ref(
      storage,
      `documents/${uuidv4()}-${selectedFile.name}`
    );
    const uploadTask = uploadBytesResumable(uploadRef, selectedFileBlob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume...
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads...
        console.log(error);
        setUploadStatus("Error in uploading file");
      },
      () => {
        // Handle successful uploads...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const fileData = {
            fileId: uuidv4(),
            fileName: selectedFile.name,
            fileUrl: downloadURL,
            uploadedAt: serverTimestamp(),
          };
          addDocumentToFirestore(fileData, currentUser).then(async (docId) => {
            alert("Document uploaded successfully.");

            setStatus("sent");

            postUploadedDocx();
          });
        });
      }
    );
  };

  const handleSFDTChange = (updatedSFDT) => {
    setSFDT(updatedSFDT);
  };

  /**
   * @abstract This function is used to post the uploaded docx to to server...
   * @async { * } True
   */
  const postUploadedDocx = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", currentUser.uid);

    await postDocument(formData)
      .then(async (res) => {
        let highlightedContent = res.data.htmlContent;
        const stringData = res.data.risk.choices[0].message.content;
        const patterns = stringData.split("\n\n");

        const dataArray = [];
        const parsedData = JSON.parse(stringData);

        for (let i = 0; i < parsedData.length; i++) {
          const item = parsedData[i];
          const dataObject = {
            category: item.category,
            clause: item.risky_sentence,
            riskDefinition: item.risk_definition,
            probability: item.probability,
            impact: item.impact_on_client,
            mitigation: item.mitigation_measure,
            probabilityAfterMitigation: item.probability_after_mitigation,
            impactAfterMitigation: item.impact_after_mitigation,
            averageRiskScore: parseFloat(
              (item.probability +
                item.impact_on_client +
                item.impact_after_mitigation +
                item.probability_after_mitigation) /
                4
            ),
            email: currentUser.email,
          };

          const highlightedSentence = `<span style="background-color: yellow">${item.risky_sentence
            .replace(/"/g, "")
            .trim()}</span>`;
          highlightedContent = highlightedContent.replace(
            new RegExp(
              `(${item.risky_sentence.replace(/"/g, "").trim()})`,
              "gi"
            ),
            highlightedSentence
          );

          dataArray.push(dataObject);
        }

        // saving the extracted data to db...
        const result = await saveToDB({ dataArray });
        const newDataArray = dataArray.map((obj, index) => {
          return { ...obj, documentId: result.data.documentIDs[index] };
        });

        setContent(newDataArray);

        setHtmlContent(highlightedContent);
        setStatus("received");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * @desc This function is used to generate a document when downloading...
   */
  const handleGenerateDocument = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/handle-document-download`,
        {
          htmlContent: htmlContent
            .replace(/style="background-color: yellow"/g, "")
            .replace(
              /<span style='color: blue;'><del>.*?<\/del><\/span>/gi,
              ""
            ),
        }
      );
      const { fileUrl } = response.data;
      setFileUrl(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadDocument = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/get-generated-file`,
        {
          fileName: fileUrl,
        },
        {
          responseType: "blob", // Specify the response type as a blob
        }
      );

      // Create a temporary URL object to create a downloadable link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileUrl;
      link.click();

      // Clean up by revoking the temporary URL object
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="">
      <div className="relative">
        {/* <Notification text={`Successfully uploaded!`} /> */}
      </div>
      <div>
        <GrammarCheckerDialog
          open={showGrammarModal}
          setOpen={setShowGrammarModal}
          setIsProceed={setIsProceed}
          sfdt={sfdt}
          onSFDTChange={handleSFDTChange}
        />
      </div>
      <div className="L-upload-main"></div>
      <div className="flex flex-col items-center justify-center p-6">
        <div>
          <h1 className="L-upload-title">Risk Assessment</h1>
          <InformationAlert text={text} />
        </div>

        <div className="mt-2 w-full flex justify-center rounded-lg border-dashed border-indigo-800 border-[2px] px-6 py-10">
          <div className="text-center">
            <DocumentIcon
              className="mx-auto h-[150px] w-[150px] text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 flex justify-center items-center text-center leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none hover:text-indigo-500"
              >
                <div className="flex justify-center">
                  {selectedFile && (
                    <div className="p-2">
                      <button
                        className="bg-blue-600 hover:bg-blue-400 cursor-pointer text-white font-medium py-2 px-4 rounded"
                        onClick={handleFileUpload}
                        disabled={!selectedFile}
                      >
                        Review
                      </button>
                    </div>
                  )}

                  {!selectedFile && (
                    <div className="text-white font-semibold bg-blue-600 rounded-lg px-4 py-2.5">
                      Choose File
                    </div>
                  )}

                  <button
                    className="ml-8 bg-blue-600 hover:bg-blue-400 cursor-pointer fond-semibold text-white font-medium py-2 px-4 rounded"
                    onClick={() => {
                      Flmngr.open({
                        apiKey: "AkQ9E0kZ",
                        urlFileManager: `${process.env.REACT_APP_BACKEND_URL}/flmngr`,
                        urlFiles: `${process.env.REACT_APP_BACKEND_URL}/server/tmp`,
                        isMultiple: false,
                        acceptExtensions: [
                          "png",
                          "jpg",
                          "jpeg",
                          "gif",
                          "webp",
                          "docx",
                        ],
                        onFinish: async (files) => {
                          const fileUrl = files[0].url;

                          const response = await fetch(fileUrl);

                          const blob = await response.blob();

                          const url = URL.createObjectURL(blob);

                          const file = new File([blob], "temp_file", {
                            type: blob.type,
                          });

                          setSelectedFile(file);

                          URL.revokeObjectURL(url);
                        },
                      });
                    }}
                  >
                    From Server
                  </button>
                </div>

                <span className="mb-4 text-sm text-gray-600 underline">
                  {selectedFile?.name}
                </span>

                {uploadProgress > 0 && (
                  <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>
                )}
                {uploadStatus && <p>{uploadStatus}</p>}

                <input
                  ref={fileInputRef}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={handleFileChange}
                  accept=".doc,.docx,application/pdf"
                />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600 text-[14px] font-semibold pt-6">
              Drag and drop the document or documents you'd like to add.
            </p>
          </div>
        </div>

        <p className="font-bold text-left p-2">Risk Assessment</p>
        {status === "not_started" && <div>Not Started</div>}
        {status === "sent" && <Spinner />}

        {content.length > 0 && htmlContent.length > 0 && (
          <div className="w-full p-4">
            <Table
              content={content}
              htmlContent={htmlContent}
              saveContentChanges={handleContentChanges}
            />
          </div>
        )}

        <div className="flex flex-1 justify-between">
          {status === "received" && (
            <button
              className="bg-indigo-600 rounded-lg text-white px-1.5 py-2.5 hover:bg-indigo-800"
              onClick={handleGenerateDocument}
            >
              Stop the updating contract
            </button>
          )}

          {fileUrl && (
            <button
              onClick={handleDownloadDocument}
              className="bg-indigo-600 rounded-lg text-white px-1.5 py-2.5 hover:bg-indigo-800 ml-6"
            >
              Download Document
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocumentUpload1;
