import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import RiskMatrix from '../risk-matrix';

export default function MatrixModal({
    matrixOpen,
    setMatrixOpen,
    content,
    currentCategory
}) {

    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={matrixOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10 w-full" initialFocus={cancelButtonRef} onClose={setMatrixOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed w-full inset-0 z-10 overflow-y-auto p-4">
                    <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-auto  mx-auto transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 w-auto text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        {
                                            matrixOpen &&
                                            <RiskMatrix 
                                                tableData={content} 
                                                currentCategory={currentCategory}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setMatrixOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}