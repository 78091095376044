import React, { createContext, useContext, useState, useEffect } from "react";
import { collection, doc, getDoc, updateDoc, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { database } from "../firebase";

export const PaymentContext = createContext(null);

const PaymentProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [hasActiveSubscription, setHasActiveSubscription] = useState(null);

    const savePaymentData = async (paymentData) => {
        try {
            if (currentUser) {
                const usersCollectionRef = collection(database, "users");
                const q = query(usersCollectionRef, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    querySnapshot.forEach(async (item) => {
                        const userDocRef = doc(database, "users", item.id);
                        await updateDoc(userDocRef, {
                            subScriptionStatus: "active"
                        });
                        setHasActiveSubscription(true);
                    });
                }
            }
        } catch (error) {
            console.error("Error saving payment data: ", error);
        }
    };

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            try {
                if (currentUser) {
                    const usersCollectionRef = collection(database, "users");
                    const q = query(usersCollectionRef, where("email", "==", currentUser.email));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {
                            const userData = doc.data();
                            const hasSubscription = userData.subScriptionStatus === "active";
                            setHasActiveSubscription(hasSubscription);
                        });
                    } else {
                        console.log("error")
                    }
                }
            } catch (error) {
                console.log("Error checking subscription status: ", error);
            }
        };

        checkSubscriptionStatus();
    }, []);

    return (
        <PaymentContext.Provider value={{ hasActiveSubscription, savePaymentData }}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentProvider;