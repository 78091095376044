import React, {useState} from 'react';
import DocViewer, {MSDocRenderer, PDFRenderer, } from '@cyntler/react-doc-viewer';


const DocumentViewer = ({fileUrl, fileName}) => {
    // Check the file extension

    return (
        <DocViewer
            pluginRenderers={[PDFRenderer, MSDocRenderer]}
            documents={[{uri: fileUrl, fileType: `${fileName.split('.').pop()}`}]}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true
                }
            }}
        />
    );
};

export default DocumentViewer;
