import React from "react";
import Flmngr from "@flmngr/flmngr-react";

function FolderManger() {
  return (
    <div>
      <button
        onClick={() => {
            Flmngr.open({
                apiKey: "AkQ9E0kZ",
                urlFileManager: "https://fm.flmngr.com/fileManager",
                urlFiles: "https://fm.flmngr.com/files",
                isMultiple: false,
                acceptExtensions: ["png", "jpg", "jpeg", "gif", "webp", "docx"],
                onFinish: (files) => {
                    console.log("User picked!");
                    console.log(files);
                }
            })
        }}
      >
        Open file manager
      </button>
    </div>
  );
}

export default FolderManger;
