/**
 * @author Julian Santiago
 * @created 10/02/2024
 */


import React, { useState, useRef, useEffect } from 'react';
import { DocumentEditorContainerComponent, Toolbar, SfdtExport, Selection, BordersAndShadingDialog, Editor, WordExport } from '@syncfusion/ej2-react-documenteditor';
import { registerLicense } from "@syncfusion/ej2-base";

import "./assets/base.css";
import "./assets/buttons.css";
import "./assets/inputs.css";
import "./assets/popups.css";
import "./assets/lists.css";
import "./assets/navigations.css";
import "./assets/splitbuttons.css";
import "./assets/dropdowns.css";
import "./assets/documenteditor.css";

import CopilotPropertyPane from '../../components/copilot-property-pane';

DocumentEditorContainerComponent.Inject(Toolbar, SfdtExport, Selection, Editor, BordersAndShadingDialog, WordExport);
registerLicense("Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW39fcHBVRmFVVEByWA==")

export default function ContractCopilot() {
    const [copilotOpen, setCopilotOpen] = useState(false);
    const [data, setData] = useState([]);

    const [content, setContent] = useState(null);

    let container = new DocumentEditorContainerComponent();


    const copilotItem = {
        prefixIcon: "e-de-ctnr-lock",
        tooltipText: "Contract Copilot",
        text: "Contract Copilot",
        id: "Copilot"
    };

    const saveItem = {
        prefixIcon: "",
        tooltipText: "Export",
        text: "Export",
        id: "export-to-word"
    }

    const items = [
        "Undo",
        "Redo",
        "Open",
        "Separator",
        "Image",
        "Table",
        "Hyperlink",
        "Bookmark",
        "Comments",
        "TableOfContents",
        "Separator",
        "Header",
        "Footer",
        "PageSetup",
        "PageNumber",
        "Break",
        "InsertFootnote",
        "InsertEndnote",
        "Separator",
        "Find",
        "Separator",
        "LocalClipboard",
        "RestrictEditing",
        "Separator",
        "TrackChanges",
        copilotItem,
        saveItem
    ];

    /**
     * @abstract This function is used to handle the copilot opening functionality...
     */
    const handleCopilotOpen = () => {
        setCopilotOpen(true);
    }

    /**
     * @abstract This function is used to handle saving text as a formatted docx file...
     */
    const handleSave = () => {
        container.documentEditor.save("document_" + Date.now(), "Docx");
    }

    /**
     * @abstract This function is used to catch the suitable sentence using syncfusion...
     * @param {*} sentence 
     */
    const scrollToSentence = (sentence) => {
        const bestMatchedSentence = findBestMatchedSentence(sentence, content).replace(/^[A-Z\s]+(?:\s|$)/, '');
        container.documentEditor.search.find(bestMatchedSentence);
        container.documentEditor.selection.characterFormat.fontColor = "Blue";
    }

    /**
     * @admiral9200
     * @abstract This function is used to find the best matched sentence using syncfusion from the quite long text...
     * @param {*} searchSentence 
     * @param {*} longText 
     * @returns { * } String
     */
    const findBestMatchedSentence = (searchSentence, longText) => {
        const sentences = longText.split('.'); // Split long text into sentences
        let bestMatch = null;
        let bestMatchSimilarity = 0;

        sentences.forEach(sentence => {
            const similarity = calculateSimilarity(searchSentence, sentence);

            if (similarity > bestMatchSimilarity) {
                bestMatch = sentence;
                bestMatchSimilarity = similarity;
            }
        });

        if (bestMatch && bestMatch.startsWith("undefined")) {
            bestMatch = bestMatch.substring(9);
        }

        return bestMatch;
    }

    /**
     * @admiral9200
     * @abstract This function is used to calculate the similarity between a sample sentence and one of long text...
     * @param {*} sentence1 
     * @param {*} sentence2 
     * @returns 
     */
    const calculateSimilarity = (sentence1, sentence2) => {
        const m = sentence1.length;
        const n = sentence2.length;
        const dp = [];

        for (let i = 0; i <= m; i++) {
            dp[i] = [i];
        }

        for (let j = 0; j <= n; j++) {
            dp[0][j] = j;
        }

        for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
                if (sentence1[i - 1] === sentence2[j - 1]) {
                    dp[i][j] = dp[i - 1][j - 1];
                } else {
                    dp[i][j] = Math.min(
                        dp[i - 1][j] + 1,
                        dp[i][j - 1] + 1,
                        dp[i - 1][j - 1] + 1
                    );
                }
            }
        }

        const maxEditDistance = Math.max(m, n);
        const similarity = 1 - dp[m][n] / maxEditDistance;

        return similarity;
    }

    /**
     * @admiral9200
     * @abstract This function is used to switch two cases...
     * @param {*} args 
     */

    const onToolbarClick = (args) => {
        switch (args.item.id) {
            case "Copilot":
                handleCopilotOpen();
                break;
            case "export-to-word":
                handleSave();
                break;
            default:
                break;
        }
    }


    /**
     * @admiral9200
     * @abstract This function is used to find a matched sentence and un-highlight the blue sentence..
     * @param {*} params 
     */
    const handleAcceptMitigationMeasure = (params) => {
        const { clause, snippet } = params;

        const wrongSnippet = snippet.split('@@@')[0];
        const correctSnippet = snippet.split('@@@')[1];

        container.documentEditor.search.find(clause, 'WholeWord');
        container.documentEditor.selection.characterFormat.fontColor = "Black";


        // Correct this part to find the wrong snippet correctly...
        container.documentEditor.search.find(wrongSnippet);
        container.documentEditor.editor.insertText(correctSnippet);
        container.documentEditor.search.find(correctSnippet);
        container.documentEditor.selection.characterFormat.fontColor = "Red";
    }

    const handleDocumentChange = (args) => {
        if (args.source.isRendered && args.source.isCreated) {
            let text = '';

            // catching the text from sfdt using serialize function...
            [...JSON.parse(container.documentEditor.serialize()).sec[0].b].forEach(item => {
                if (item.i) {
                    item.i.forEach(index => {
                        text += index?.tlp;
                    })
                }
            });

            setContent(text);
        }
    }

    return (
        <div>
            <DocumentEditorContainerComponent
                ref={(scope) => {
                    container = scope;
                }}
                id="container"
                toolbarItems={items}
                height={'800px'}
                serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                enableToolbar={true}
                enableSpellCheck={true}
                enableTrackChanges={true}
                toolbarClick={onToolbarClick}
                documentChange={handleDocumentChange}
            >
                <CopilotPropertyPane
                    open={copilotOpen}
                    setOpen={setCopilotOpen}
                    data={data}
                    scrollToSentence={scrollToSentence}
                    handleAcceptMitigationMeasure={handleAcceptMitigationMeasure}
                    content={content}
                    setData={setData}
                />
            </DocumentEditorContainerComponent>
        </div>
    )
}