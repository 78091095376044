import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const baseUrl = window.location.origin;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${baseUrl}/#/payment-status`
            }
        });

        if (result.error) {
            console.log(result.error.message);
        } else {
        }
    }

    return (
        <div className="relative z-10 w-[100%] bg-[#f3f9ff] flex justify-center">
        <form
            onSubmit={handleSubmit}
            className="p-32 w-[640px]"
        >
            <PaymentElement/>
            <div className="pt-8">
                <button 
                    type="submit" 
                    disabled={!stripe}
                    className="px-1.5 py-2.5 bg-indigo-600 text-white rounded-lg w-full hover:bg-indigo-800"
                >
                    Confirm and Pay
                </button>
            </div>
        </form>
        </div>
    );
}

export default CheckoutForm;