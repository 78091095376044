import DocumentUpload from '../../components/documentupload/DocumentUpload'
import './style.css'

function Assign(){
    return(
        <div className='L-assign bg-blue-50 pb-12 relative z-10'>
            
            <DocumentUpload/>
            
        </div>
    )
}
export default Assign