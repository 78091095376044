import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

import {
  DocumentEditorContainerComponent,
  Toolbar,
  SfdtExport,
  Selection,
  BordersAndShadingDialog,
  Editor,
  WordExport,
  SpellChecker,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";

import "./assets/base.css";
import "./assets/buttons.css";
import "./assets/inputs.css";
import "./assets/popups.css";
import "./assets/lists.css";
import "./assets/navigations.css";
import "./assets/splitbuttons.css";
import "./assets/dropdowns.css";
import "./assets/documenteditor.css";
import {
  assessmentForUpdatedDocx,
  convertDocumentToSFDT,
} from "../../api/document";
DocumentEditorContainerComponent.Inject(
  Toolbar,
  SfdtExport,
  Selection,
  Editor,
  BordersAndShadingDialog,
  WordExport,
  SpellChecker
);
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW39fcHBVRmFVVEByWA=="
);

const GrammarCheckerContainer = (sfdt) => {
  let container = new DocumentEditorContainerComponent();

  const saveItem = {
    prefixIcon: "",
    tooltipText: "Save as a new file",
    text: "Save",
    id: "export-to-word",
  };

  const proceedItem = {
    prefixIcon: "",
    tooltipText: "Assessment",
    text: "Proceed",
    id: "proceed-to-assessment",
  };

  const items = [
    "Undo",
    "Redo",
    "Open",
    "Separator",
    saveItem,
    // proceedItem,
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "Comments",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "InsertFootnote",
    "InsertEndnote",
    "Separator",
    "Find",
    "Separator",
    "LocalClipboard",
    "RestrictEditing",
    "Separator",
    "TrackChanges",
  ];

  /**
   * @admiral9200
   * @abstract This function is used to switch two cases...
   * @param {*} args
   */

  useEffect(() => {
    // Enable spell checker...
    container.documentEditor.spellChecker.removeUnderline = false;
    container.documentEditor.spellChecker.enableSpellCheck = true;
    container.documentEditor.spellChecker.languageID = 1033;
    container.documentEditor.spellChecker.enableOptimizedSpellCheck = true;
    container.documentEditor.spellChecker.allowSpellCheckAndSuggestion = true;

    console.log("ddd: ", sfdt);
    container.documentEditor.open(sfdt.sfdt);
  }, []);

  const onToolbarClick = (args) => {
    switch (args.item.id) {
      case "export-to-word":
        handleSave();
        break;
      case "proceed-to-assessment":
        proceedToAssessment();
        break;
      default:
        break;
    }
  };

  /**
   * @abstract This function is used to handle saving text as a formatted docx file...
   */
  const handleSave = () => {
    let fileName = "document_" + Date.now();
    container.documentEditor.save(fileName, "Docx");

    setTimeout(() => {
      alert(`Updated file was been saved as ${fileName}! Please check the downloads folder!`);
      window.location.reload();
    }, 500)
  };

  const handleDocumentChange = (args) => {
    if (args.source.isRendered && args.source.isCreated) {
      let text = "";

      // catching the text from sfdt using serialize function...
      [...JSON.parse(container.documentEditor.serialize()).sec[0].b].forEach(
        (item) => {
          if (item.i) {
            item.i.forEach((index) => {
              text += index?.tlp;
            });
          }
        }
      );
    }
  };

  /**
   * Proceed to assessment after correcting some mistakes...
   */
  const proceedToAssessment = async () => {
    let text = "";

    // catching the text from sfdt using serialize function...
    [...JSON.parse(container.documentEditor.serialize()).sec[0].b].forEach(
      (item) => {
        if (item.i) {
          item.i.forEach((index) => {
            text += index?.tlp;
          });
        }
      }
    );

    const response = await assessmentForUpdatedDocx({ text });
    console.log("processed data: ", response.data);
  };

  return (
    <div>
      <DocumentEditorContainerComponent
        ref={(scope) => {
          container = scope;
        }}
        id="container"
        toolbarItems={items}
        height={"800px"}
        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        enableToolbar={true}
        enableSpellCheck={true}
        enableTrackChanges={true}
        toolbarClick={onToolbarClick}
        documentChange={handleDocumentChange}
      />
    </div>
  );
};

const GrammarCheckerDialog = ({
  open,
  setOpen,
  setIsProceed,
  sfdt,
  onSFDTChange,
}) => {
  const cancelButtonRef = useRef(null);

  const [showContainer, setShowContainer] = useState(false);

  useEffect(() => {
    console.log("sfdt: ", sfdt);
  }, [sfdt, onSFDTChange]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {!showContainer && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Are you planning to check your document using Grammarly?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You can update your document using this feature.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        setIsProceed(false);
                        setShowContainer(true);
                      }}
                    >
                      Yes, I will update my document
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        setIsProceed(true);
                        setOpen(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      No, I don't need to update my document.
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            )}

            {showContainer && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-[80%] mt-[120px] m-8">
                  <div>
                    {sfdt?.sfdt?.length > 0 && (
                      <GrammarCheckerContainer sfdt={sfdt} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GrammarCheckerDialog;
