import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "./pages/footer";
import Header from "./pages/header";
import Main from "./pages/main";
import About from "./pages/about";
import Contact from "./pages/contact";
import Riskassessment from "./pages/riskassessment";
import Assign from "./pages/assign";
import Upload from "./pages/upload";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./utils/protected/ProtectedRoute";
import Documents from "./pages/documents";
import { GoogleOAuthProvider } from "@react-oauth/google";
import FolderManger from './pages/folder-system';
import { Preloader } from "./components/preloader";
import Pricing from "./pages/pricing";
import Checkout from "./pages/checkout";
import PaymentStatus from "./pages/payment-status";
import PaymentProvider from "./context/PaymentContext";
import PaymentProtectedRoute from "./utils/protected/PaymentProtectedRoute";
import LawyerRegistration from "./pages/lawyer-register";
import ContractCopilot from "./pages/contract-copilot";
import TmpWarningPage from "./pages/tmp-warning";
import TermsAndConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import GrammarChecker from "./pages/grammar-checker";

function App() {
  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_OAUTH_CLIENT_ID}`}>
      <AuthProvider>
        <PaymentProvider>
          <div className="App-main">
            <div className="App">
              <Preloader />
              <Header />
              <Routes>
                <Route path="/" element={<Main />} index />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="checkout/:id" element={<Checkout />} />
                <Route
                  path="lawyer-registration"
                  element={
                    <ProtectedRoute>
                      <LawyerRegistration />
                    </ProtectedRoute>
                  }
                />
                <Route path="payment-status" element={<PaymentStatus />} />

                <Route
                  path="risk"
                  element={
                    <ProtectedRoute>
                      <Riskassessment />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="grammar"
                  element={
                    <ProtectedRoute>
                      <GrammarChecker />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="assign"
                  element={
                    <ProtectedRoute>
                      <Assign />
                    </ProtectedRoute>
                  }
                />

                <Route path="signup" element={<SignUp />} />
                <Route path="signin" element={<SignIn />} />
                <Route path='folder-system' element={<ProtectedRoute><FolderManger /></ProtectedRoute>} />
                <Route
                  path="upload"
                  element={
                    <ProtectedRoute>
                      <Upload />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="documents"
                  element={
                    <ProtectedRoute>
                      <Documents />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="tmp-warning"
                  element={
                    <ProtectedRoute>
                      <TmpWarningPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </PaymentProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
