import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "../../components/checkout";

function Checkout() {
    const { id } = useParams();
    const [clientSecret, setClientSecret] = useState(null);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // const backendUrl = 'https://contractee-mvp.onrender.com';

    const appearance = {
        theme: 'night'
      };

    const stripePromise = loadStripe(`${ process.env.REACT_APP_STRIPE_API_OPEN_kEY }`);

    const switchAmount = (method) => {
        const AMOUNTS = {
            free: 0.5,
            deluxe: 99,
            premium: 45
        };

        return AMOUNTS[method] || 0;
    }

    useEffect(() => {
        console.log("backend url: ", backendUrl)
        const getClientSecret = async () => {
            try {
                const response = await axios.post(`${backendUrl}/create-payment-intent`, {
                    amount: switchAmount(id) * 100,
                    currency: 'usd',
                    paymentMethodType: 'card'
                });

                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error creating payment intent: ', error.message);
                throw new Error("Failed to create payment intent");
            }
        }

        getClientSecret();
    }, [])

    if (clientSecret) {
        return (
            <div className="min-h-screen">
                <div className="flex flex-1 justify-center items-center">
                <Elements 
                    stripe={stripePromise} 
                    options={{ 
                        clientSecret: clientSecret, 
                        appearance: appearance 
                    }}
                >
                    <CheckoutForm />
                </Elements>
            </div>
            </div>
        )
    } else {
        return (
            // <div>
            //     {id}
            // </div>
            <></>
        )
    }
}

export default Checkout;
