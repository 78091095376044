import React from 'react';
import './style.css'
import { useNavigate } from 'react-router-dom';

function PrimaryButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-prim bg-white text-black border border-black hover:border-white hover:bg-transparent hover:text-white font-medium py-2 px-4 rounded"
        >
            {text}
        </button>
    );
}

function SecondaryButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-sec bg-transparent hover:bg-white text-white font-medium hover:text-black py-2 px-4 border border-white hover:border-transparent rounded"
        >
            {text}
        </button>
    );
}
function PrimaryMainButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-primm"
        >
            {text}
        </button>
    );
}

function SecondaryMainButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-primm"
        >
            {text}
        </button>
    );
}
function SubmitButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-sec w-full bg-transparent hover:bg-black text-black font-medium hover:text-white py-2 px-4 border border-black hover:border-white rounded"
        >
            {text}
        </button>
    );
}
function PricingButton({ text, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <button
            onClick={handleClick}
            className="L-button-sec !p-3 mt-12 bg-blue-600 hover:bg-black text-white font-medium py-2 px-4 border border-black hover:border-white rounded"
        >
            {text}
        </button>
    );
}

export { PrimaryButton, SecondaryButton, PrimaryMainButton, SecondaryMainButton, SubmitButton, PricingButton };
